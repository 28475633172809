import React from "react";

function Home() {
    return (
        <div className="wrapper">
            <div className="left">
                <p className="main-para">Wir sind bald wieder zurück!</p>
                <p>.. oder wie ein bekannter Filmcharakter es sagen würde:</p>
                <img className="gif-image" src={process.env.PUBLIC_URL+"arnie.gif"} alt="Arnold Schwarzenegger im Film 'Terminator'" />
            </div>
            <div className="right">
                <a href="/" rel="noopener noreferrer"><h1>Dorian Nebojsa Stanojevic Portfolio</h1></a>
                <h2>Wartungsmodus</h2>
                <div className="mobile-small-left">
                <p className="main-para">Wir sind bald wieder zurück!</p>
                <p>.. oder wie ein bekannter Filmcharakter es sagen würde:</p>
                <img className="gif-image" src={process.env.PUBLIC_URL+"arnie.gif"} alt="Arnold Schwarzenegger im Film 'Terminator'" />
                </div>
                <p>Finde mich in der Zwischenzeit auf sozialen Medien:</p>
                <ul className="social-media-nav">
                    <li><a href="https://www.xing.com/profile/Dorian_Stanojevic/" title="Finde mich auf Xing" target="_blank" rel="noopener noreferrer"><img alt="Xing Icon" width="55px" src={process.env.PUBLIC_URL+"xing_icon.svg"}/></a></li>
                    <li><a href="https://www.linkedin.com/in/dorian-neboj%C5%A1a-stanojevi%C4%87-ab5192166/" title="Finde mich auf LinkedIn" target="_blank" rel="noopener noreferrer"><img alt="LinkedIn Icon" width="55px" src={process.env.PUBLIC_URL+"linkedin_icon.svg"}/></a></li>
                </ul>
            </div>
        </div>
    )
}








export default Home;